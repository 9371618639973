/* src/App.css */

/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

/* Aspect Ratio Container */
.app {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff0f5; /* Light sakura background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  min-height: 100vh;
}

/* Header */
header {
  display: flex;
  align-items: center;
  background-color: #ffc0cb; /* Sakura color */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 0 0;
}

.header-left {
  flex: 1;
}

.header-left h6 {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
  color: #8b0000; /* Dark sakura */
  font-family: 'Arial', sans-serif;
}

.header-center {
  flex: 2;
  display: flex;
  justify-content: center;
}

.header-center a {
  margin: 0 12px;
  text-decoration: none;
  color: #666;
  font-size: 12px;
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.header-right div {
  cursor: pointer;
  color: #8b0000; /* Dark sakura */
  font-size: 14px;
  align-self: center; /* Ensure vertical alignment */
}

/* Main Content */
main {
  padding: 20px;
}

.top-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.pill-button {
  border: none;
  background-color: #e0e0e0; /* Space gray */
  color: #666;
  padding: 10px 20px;
  border-radius: 50px;
  margin: 0 10px;
  cursor: pointer;
}

.pill-button.active {
  background-color: #d6336c; /* Sakura color */
  color: white;
}

.flashcard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flashcard-box {
  width: 90%;
  max-width: 300px;
  height: 100px;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.mnemonics-box {
  height: 250px; /* 2.5 times the other boxes */
}

.flashcard-box p {
  margin: 10px 0;
  color: #333;
}

/* Center the buttons inside the boxes */
.flashcard-box button {
  background-color: #d6336c; /* Sakura color */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Center the revealed text */
#meaning-text,
#mnemonics-text {
  margin-top: 10px;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#mnemonics-text p {
  margin: 5px 0;
  font-size: 13px;
}

.sign-in-instruction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures the container takes full available height */
  flex: 1; /* Allows the container to grow and fill space */
}

.sign-in-instruction p {
  color: #8b0000; /* Dark sakura color */
  font-size: 1.2em; /* Scalable font size */
  text-align: center;
}

/* Bottom Buttons */
.bottom-buttons {
  position: relative;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.bottom-buttons button {
  background-color: #d6336c; /* Sakura color */
  color: white;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 48%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-left h6 {
    font-size: 18px;
  }
  .header-right div {
    font-size: 14px;
  }
  .pill-button {
    padding: 8px 16px;
    font-size: 12px;
  }
  .flashcard-box {
    padding: 15px;
    height: 90px;
  }
  .mnemonics-box {
    height: 225px;
  }
  .flashcard-box button {
    padding: 8px 16px;
    font-size: 12px;
  }
  .bottom-buttons button {
    padding: 12px 16px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .header-left h6 {
    font-size: 16px;
  }
  .header-right div {
    font-size: 12px;
  }
  .pill-button {
    padding: 6px 12px;
    font-size: 10px;
  }
  .flashcard-box {
    padding: 10px;
    height: 75px;
  }
  .mnemonics-box {
    height: 187.5px;
  }
  .flashcard-box button {
    padding: 6px 12px;
    font-size: 10px;
  }
  .bottom-buttons button {
    padding: 10px 12px;
    font-size: 10px;
  }
}
